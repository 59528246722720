import * as types from '../actions/dashboardActions';

const initState = {
  ini_data: null,
  data: null,
  initiation_count: {
    ini_count: '0',
    pr_count: '0',
    qr_count: '0',
    qa_count: '0',
  },
  ini_totalCount: 0,
  department_chart: {},
  project_chart: {},
  purpose_chart: {},
  chart_data: {},
  action_chart_data: {},
  line_chart_data: [],
  action_line_chart: [],
  imp_data: null,
  implementation_count: {
    capa_implementation: '0',
    capa_closure: '0',
    check_performed: '0',
    capa_retired: '0',
  },
  imp_totalCount: 0,
  filter1: 'nco_source',
  filter2: 'nco_source',
  filter3: 'nco_department',
  filter4: 'nco_source',
  filter5: 'nco_department',
  action_filter1 : 'capa_type',
  action_filter2: 'capa_type',
  action_filter3: 'root_cause',
  action_filter4: 'capa_type',
  action_filter5: 'Preventive Action',
  count_data: [],
  date_obj: {},
  imp_count_data: [],
  line_filter_column: "nco_source",
  line_filter_value: "Incoming",
  action_filter_data: {},
  filter_data: {},
  line_year1: "2023",
  line_year2: "2024",
 tabKey: "1",
 action_item_count: {},
};

console.log(initState.chart_data);

const dashboard = (state = initState, action) => {
  switch (action.type) {
    case types.GET_DASHBOARD_DATA:
      return {
        ...state,
        ini_data: action.data.data,
        ini_totalCount: action.data.data.length,
        initiation_count: action.initiation_count,
        department_chart: action.data.depatment_chart[0] || {},
        project_chart: action.data.project_chart[0] || {},
        purpose_chart: action.data.purpose_chart[0] || {},
      };
    case types.DASHBOARD2_DATA:
      return {
        ...state,
        chart_data: action.data,
      };
      case types.DASHBOARD2_CHART2_DATA:
        return {
          ...state,
          line_chart_data: action.data,
        };
        case types.DASHBOARD2_ACTION_CHART2:
          return {
            ...state,
            action_line_chart : action.data,
          };
        case types.GET_DASHBOARD2_FILTER:
          return {
            ...state,
            filter_data: action.data,
          };
          case types.GET_ACTION_ITEM_FILTER:
          return {
            ...state,
            action_filter_data: action.data,
          };
    case types.GET_DASHBOARD_IMP:
      return {
        ...state,
        imp_data: action.data,
        implementation_count: action.data,
        imp_totalCount: action.total,
      };
    case types.GET_DASHBOARD2_IMP:
      return {
        ...state,
        count_data: action.data,
      };
    case types.GET_DASHBOARD2_COUNT:
      return {
        ...state,
        imp_count_data: action.data,
      };
       case types.GET_ACTION_ITEM_COUNT:
      return {
        ...state,
        action_item_count: action.data,
      };
    case types.ACTION_DASHBOARD_DATA:
        return {
          ...state,
          action_chart_data: action.data,
        };
    case types.GET_DASHBORD_INITIATION:
      return {
        ...state,
        data: action.data,
        excelData: action.excelData,
        totalCount: action.data.length,
      };
    case types.GET_DASHBOARD_ACTIONITEM:
      return {
        ...state,
        data: action.data,
        excelData: action.excelData,
        totalCount: action.data.length,
      };
    case 'SET_DASHBOARD_FILTER':
      return {
        ...state,
        [action.key]: action.data,
      };
    case 'SET_DASHBOARD_DATE_FILTER':
      return {
        ...state,
        [action.key]: action.data,
      };
      case 'SET_TAB_KEY':
        return {
          ...state,
          tabKey: action.data,
        };

    default:
      return state;
  }
};

export default dashboard;
